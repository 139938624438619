<template>
  <Form :heading1="'Update an appointee'">
    <template v-slot:details>
      <div class="md:flex md:space-x-12 block space-x-0">
        <div class="flex-1">
          <TextInput
            :label="'National ID'"
            @input="setnationalId"
            :defaultValue="appointment.appointee.nationalId"
            :not="true"
            :types="'text'"
          />
          <TextInput
            :label="'Short description'"
            :defaultValue="appointment.appointee.description"
            :not="true"
            @input="setDescription"
            :types="'textarea'"
          />
        </div>
        <div class="flex-1">
          <div v-if="!appointment.appointee.imageUrl">
            <TextInput
              :label="'Upload Profile photo'"
              :not="true"
              @input="setProfile"
              :types="'file'"
            />
          </div>
          <div v-else class="appointee_img">
            <div class="current_info">
              <img
                v-if="appointment.appointee.imageUrl"
                :src="`http://minicaaf.primature.gov.rw/tmsbackend/api/v1/appointee/load-file/profile/${appointment.appointee.id}`"
                alt=""
                class="appointee-image"
              />
              <p>{{ appointment.appointee.names }}</p>
            </div>
            <div class="edit">
              <TextInput
                :label="'Change Profile photo'"
                :not="true"
                @input="setProfile"
                :types="'file'"
                class="edit_btn"
              />
            </div>
          </div>
          <DragAndDrop
            :label="'CV'"
            :not="true"
            @input="setCV"
            :cvPath="cvPath"
          />
        </div>
      </div>
      <Button :first-l="'Prev'" :last-l="'Save'" @action="doSave" />
    </template>
  </Form>
</template>

<script>
import Button from "/src/components/form/Buttons.vue";
import TextInput from "/src/components/form/TextInput.vue";
import Form from "/src/components/form/Form.vue";
import DragAndDrop from "/src/components/form/DragAndDrop.vue";
import { mapActions, mapState } from "vuex";

export default {
  name: "UpdateThirdStep",

  data() {
    return {
      cvPath: "",
      clicked: false,
    };
  },
  components: {
    Button,
    TextInput,
    Form,
    DragAndDrop,
  },
  computed: {
    ...mapState("appointment", { appointment: "selectedAppointment" }),
  },
  created() {
    this.cvPath =
      this.appointment.appointee.cvPath.toString().split("/")[4].split("-")[0] +
      "." +
      this.appointment.appointee.cvPath.toString().split("/")[4].split(".")[1];
  },
  methods: {
    setnationalId(value) {
      this.appointment.appointee.nationalId = value;
    },
    setProfile(value) {
      this.appointment.appointee.image = value;
    },
    setDescription(value) {
      this.appointment.appointee.description = value;
    },
    setCV(value) {
      this.appointment.appointee.cvPath = value;
    },

    ...mapActions({ updateAppointment: "appointment/updateAppointment" }),
    doSave() {
      const { nationalId, image, description, cvPath } =
        this.appointment.appointee;
        let imageUrl = image;

      if (description == "") {
        this.$store.dispatch("notification/SET_NOTIFICATION", {
          msg: "Description is required",
          uptime: 1000,
          hasError: true,
        });
      } else if (isNaN(nationalId)) {
        this.$store.dispatch("notification/SET_NOTIFICATION", {
          msg: "National ID seems to be invalid",
          uptime: 1000,
          hasError: true,
        });
      } else {
        setTimeout(() => {
          this.clicked = false;
        }, 5000);
        if (!this.clicked) {
          this.clicked = true;
          this.updateAppointment({ nationalId, imageUrl, description, cvPath });
          this.$store.dispatch("notification/SET_NOTIFICATION", {
            msg: "Updating appointment ...",
            uptime: 1000,
          });
        }
      }
    },
  },
};
</script>

<style>
.appointee_img {
  display: flex;
  align-items: center;
  justify-content: space-between !important;
  flex-wrap: wrap;
}
.appointee-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  display: flex;
}
.appointee_img_input {
  height: 40px;
}

.edit_btn {
  width: 200px;
  margin-right: 120px;
}
</style>
